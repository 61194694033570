@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

.websnapse-title {
  color: #786fa6;
}

.slider-title {
  color: #ffffff;
}

.MuiSlider-thumb {
  color: white;
}
.MuiSlider-rail {
  color: white;
}
.MuiSlider-track {
  color: black;
}

.node-actions {
}

.snapse-controls-play {
  border-radius: 50%;
}

body {
  font-family: "Raleway", sans-serif;
}
.container{
  width:100vw;
}
/*   @font-face {
    font-family: "Computer Modern";
    src: url('http://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunsx.otf');
    font-weight: bold;
  }
  @font-face {
    font-family: "Computer Modern";
    src: url('http://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunsi.otf');
    font-style: italic, oblique;
  }
  @font-face {
    font-family: "Computer Modern";
    src: url('http://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunbxo.otf');
    font-weight: bold;
    font-style: italic, oblique;
  } */
