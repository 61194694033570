.btncontainer{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.btnMode{
    width: 100%;
    margin: auto;
    padding: 1em;
    color:rgb(147, 129, 225);
}
.btnMode:hover{
    background-color: rgb(81, 73, 118);
    color:white;
}
.menuform{
    width: 80%;
}
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 20px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100vh;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    font-size: 1em;
    padding-top: 5vh;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    display: flex;
    margin: 0;
  }
  
  /* Individual item */
  .bm-item {
    display: flex;
    flex-direction: column;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(180, 161, 161, 0.3);
  }